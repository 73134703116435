<template>
  <div class="widgetContainer widgetContainer--scrollable">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <div class="title">
          Accounts
        </div>
        <i class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <template v-if="loading">
        <el-card v-for="x in [1,2,3]" :key="x" class="mb-1">
          <el-skeleton animated>
            <template slot="template">
              <el-skeleton-item variant="text" style="margin-right: 16px;" />
              <el-skeleton-item variant="text" style="width: 30%;" />
              <el-skeleton-item variant="text" style="margin-right: 16px; width: 70%" />
            </template>
          </el-skeleton>
        </el-card>
      </template>
      <template v-else>
        <template v-if="accounts.length">
          <div v-for="(group,index) in groupedAccounts" :key="index" class="mb-2">
            <div style="font-size: 20px; font-weight: bold; margin-bottom: .5rem">
              {{ group.businessName }}
            </div>
            <account-card v-for="account in group.accounts" :key="account.id" :account="account" @click.native="selectAccount(account)" />
          </div>
        </template>
        <template v-else>
          <div style="text-align: center; font-size: 24px">
            No Accounts Found.
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {accountService} from '@/services/solidfi';
import {mapGetters} from 'vuex';
import AccountCard from '@m/send/views/ShiflInstantPay/AccountCard';
import {contactName} from '@/utils/general';

export default {
  name: 'PersonAccounts',
  components: {AccountCard},
  data(){
    return {
      loading: false,
      accounts: []
    }
  },
  created() {
    this.fetchPersonAccounts()
  },
  computed:{
    ...mapGetters('send',['get_shifl_instant_pay_person','shifl_instant_pay_person_accounts']),
    groupedAccounts(){
      const fnPushAccountToProp = (accounts,acc,prop) => {
        if (!Object.hasOwnProperty.call(accounts,prop)) {
          accounts[prop] = {
            businessName: acc.type === 'businessChecking' ? acc.businessName : prop,
            accounts: []
          }
        }
        accounts[prop].accounts.push(acc);
      }
      let accounts = {}
      this.accounts.forEach(acc => {
        if (acc.type === 'businessChecking'){
          fnPushAccountToProp(accounts,acc,acc.businessId)
        }else {
          fnPushAccountToProp(accounts,acc,contactName(this.get_shifl_instant_pay_person))
        }
      })
      return accounts;
    }
  },
  methods:{
    async fetchPersonAccounts(){
      this.loading = true
      const personId = this.get_shifl_instant_pay_person?.id
      let res = await accountService.getAll(100,null,personId);
      this.accounts = res.data
      this.loading = false
    },
    async selectAccount(account){
      this.$store.commit('send/set_shifl_instant_pay_selected_account',account)
      this.$emit('goTo','IntrabankTransfer');
    }
  }
}
</script>

<style scoped>

</style>